import React, { Fragment } from "react";
import Navbar from "./components/navbar/Navbar";
import "./App.css";
import Home from "./pages/Home";
import Team from "./pages/Team";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Porfolio from "./pages/Porfolio";
import Contact from "./pages/Contact";

import Footer from "./components/footer/Footer";
import ScrollTop from "./components/scroll/ScrollTop";
import { Suspense } from "react";
import { Link, Route, Routes, BrowserRouter } from "react-router-dom";
import PortfolioElement from "./pages/PortfolioElement";
import Portfolio from "./pages/Porfolio";

import Logo from "./images/logo_tagline.png";

function App() {
  const url = "https://app.aidobotics.ai/";

  return (
    <Fragment>
      <main className="main">
        <div className="logo">
          <a href={url}>
            <img src={Logo} alt="" className="logo_with_tagline" />
          </a>
        </div>

        <div style={{ textAlign: "center" }}>
          <a href={url}>
            <button className="button">Get Started</button>
          </a>
        </div>

        <Contact />

        {/* <Home /> */}
        {/* <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} /> */}
        {/* <Route path="/portfolio" element={<Porfolio />} /> */}
        {/* <Route path="/portfolio_element" element={<PortfolioElement />} /> */}

        {/* <Route path="/about" element={<About />} />
              <Route path="/team" element={<Team />} />
              <Route path="/skills" element={<Skills />} />
              <Route path="/portfolio" element={<Porfolio />} />
              <Route path="/contact" element={<Contact />} /> */}
        {/* </Routes>
          </Suspense>
        </BrowserRouter> */}
      </main>
      {/* <Footer /> */}
      {/* <ScrollTop /> */}
    </Fragment>
  );
}

export default App;
