import React from "react";
import { UilEnvelopeEdit } from "@iconscout/react-unicons";

function Contact() {
  return (
    <section className="contact section" id="contact">
      {/* <h2 className="section__title">Contact</h2>
      <span className="section__subtitle">Get in touch</span> */}

      <div className="contact__container container">
        <div className="contact__wrapper">
          <a href="mailto:info@aidobotics.ai">
            <div className="contact__information">
              <div>
                <h3 className="contact__title">Contact</h3>
                <UilEnvelopeEdit className="contact__icon" />
                <span className="contact__subtitle">info@aidobotics.ai</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
